import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as roomActions from '../actions/roomActions';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';
import { Icon, Label, Menu, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Tooltip from '@material-ui/core/Tooltip';
import PreviousRoomsContent from './PreviousRoomsContent';

const tabs =
	[
		'media',
		'appearence',
		'advanced'
	];

const styles = (theme) =>
	({
		root :
			{
			},
		dialogPaper :
			{
				width                          : '30vw',
				[theme.breakpoints.down('lg')] :
					{
						width : '40vw'
					},
				[theme.breakpoints.down('md')] :
					{
						width : '50vw'
					},
				[theme.breakpoints.down('sm')] :
					{
						width : '70vw'
					},
				[theme.breakpoints.down('xs')] :
					{
						width : '90vw'
					}
			},
		tabsHeader :
			{
				flexGrow : 1
			},
		content	:
			{
				padding : '15px'
			}
	});

const PreviousRooms = ({
	handleClosePreviousRooms,
	previousRoomsOpen,
	classes
}) =>
{
	const intl = useIntl();

	return (
		<Dialog className={classes.root}
			open={previousRoomsOpen}
			onClose={() => handleClosePreviousRooms(false)}
			classes={{
				paper : classes.dialogPaper
			}}
		>
			<DialogTitle id='form-dialog-title'>
				<FormattedMessage
					id='settings.settings'
					defaultMessage='Previous Rooms (recorded)'
				/>
			</DialogTitle>

			<PreviousRoomsContent />

			<DialogActions>
				<Button onClick={() => handleClosePreviousRooms(false)} color='primary'>
					<FormattedMessage
						id='label.close'
						defaultMessage='Close'
					/>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

PreviousRooms.propTypes =
	{
		handleClosePreviousRooms :	PropTypes.func.isRequired,
		previousRoomsOpen        :	PropTypes.bool.isRequired,
		classes                  :	PropTypes.object.isRequired
	};

const mapStateToProps = (state) => ({
	previousRoomsOpen : state.room.previousRoomsOpen
});

const mapDispatchToProps = {
	handleClosePreviousRooms : roomActions.setPreviousRoomsOpen
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.previousRoomsOpen === next.room.previousRoomsOpen
			);
		}
	}
)(withStyles(styles)(PreviousRooms));
