import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logger from '../Logger';
import '../toaster.css';
import classnames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Auth0Lock from 'auth0-lock';
import { WebAuth } from 'auth0-js';
import Dialog from '@material-ui/core/Dialog';
import { getApiUrl } from '../urlFactory';

const logger = new Logger('Login');

const styles = function(theme)
{
	return {
		root :
        {
        	display              : 'flex',
        	width                : '100%',
        	height               : '100%',
        	backgroundColor      : window.config.theme.backgroundColor ? window.config.theme.backgroundColor : 'var(--background-color)',
        	backgroundImage      : `url(${window.config ? window.config.background : null})`,
        	backgroundAttachment : 'fixed',
        	backgroundPosition   : 'center',
        	backgroundSize       : 'cover',
        	backgroundRepeat     : 'no-repeat'
        },
		dialogPaper :
			{
				width                          : '30vw',
				padding                        : theme.spacing(2),
				[theme.breakpoints.down('lg')] :
				{
					width : '40vw'
				},
				[theme.breakpoints.down('md')] :
				{
					width : '50vw'
				},
				[theme.breakpoints.down('sm')] :
				{
					width : '70vw'
				},
				[theme.breakpoints.down('xs')] :
				{
					width : '90vw'
				}
			}
	};
};
const LoginAuth0Callback = ({
	classes
}) =>
{
	const intl = useIntl();

	const [ userMessage, setUserMessage ] = useState();

	useEffect(() =>
	{
		setUserMessage('Trying to get your user details...');
		const hash = window.location.hash.substring(1).split('&');

		hash.forEach((e) =>
		{
			const param = e.split('=');

			if (param[0] == 'access_token')
			{
				fetch(getApiUrl('AuthenticateWithToken'), {
					method  : 'POST',
					headers : {
						'Content-Type' : 'application/json'
					},
					body : JSON.stringify({
						accessToken : param[1],
						type        : 'enterprise'
					})
				})
					.then((response) => response.json())
					.then((data) =>
					{
						console.log(data);
						setUserMessage(data.message);
						if (data.success)
						{
							window.location = data.redirect;
						}
						else
						{
							setTimeout(() =>
							{
								window.location = data.redirect;
							}, 3000);
						}
					});
			}
			else if (param[0] === 'error_description')
			{
				setUserMessage(unescape(param[1]));
			}
		});
	}, []);

	return (
		<div className={classes.root}>
			<Dialog
				open
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<h2>{userMessage}</h2>
			</Dialog>
		</div>
	);
};

LoginAuth0Callback.propTypes =
{
	classes : PropTypes.object.isRequired
};

export default withStyles(styles)(LoginAuth0Callback);